.lastSection {
  /* background-color: #11243e; */
  /* clip-path: polygon(0 25%, 100% 0%, 100% 100%, 0% 100%); */
  /* margin-top: -30vh; */

  position: relative;
  /* top: 200px; */
  /* display: flex; */
  /* padding-top: 13vh; */
  /* justify-items: center; */
  /* z-index: 2; */
}

@media only screen and (max-width: 600px) {
  .lastSection {
    padding: 15rem 0;
    /* clip-path: polygon(0 29%, 100% 20%, 100% 99%, 0% 100%); */
  }
}
