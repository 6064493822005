/* .headerSectionOnly {
  background-color: silver;
  height: 80vh;
}
 */

/* Target Mobile */
@media only screen and (max-width: 600px) {
  .headerSectionOnly {
    height: 50vh !important;
  }
  .gradientBackground {
    /* display: none; */
    height: 50vh !important;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%) !important;
  }
}

.makeImageSmaller {
  width: 300px;
}
.gradientBackground {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  width: 100vw;
  height: 50vh;
  clip-path: polygon(0 0, 100% 0, 100% 57%, 0 100%);
}

.headerSectionOnly {
  min-height: 65vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
