.genContainer {
  /* background-color: #11243e !important; */
  /* width: 100vw; */
  /* height: 120vh; */
  /* padding: 8rem 0; */
  display: flex;
  align-items: center;
  border: 1px solid black;
  /* clip-path: polygon(0 29%, 100% 6%, 100% 80%, 0% 100%); */
  color: white;
}

@media only screen and (max-width: 600px) {
  .genContainer {
    padding: 1.5rem 0;
    /* clip-path: polygon(0 29%, 100% 20%, 100% 99%, 0% 100%); */
  }
}
